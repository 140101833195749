import base64url from "base64-url";
import isObject from "lodash/isObject";
import queryString from "query-string";
import { useRef, useState } from "react";
import useIsMounted from "./useIsMounted";

export interface IOAuthResponse {
  provider: "google";
  accessToken: string;
}

interface IOAuthState {
  loading: boolean;
  response: IOAuthResponse | null;
  error: Error | null;
}

export default function useOAuth(connectHref: string, onResponse?: (response: IOAuthResponse) => void) {
  const popupRef = useRef<Window | null>(null);
  const isMounted = useIsMounted();

  const [state, setState] = useState<IOAuthState>({
    loading: false,
    response: null,
    error: null,
  });

  const safeSetState = (state: IOAuthState) => {
    if (isMounted.current) {
      setState(state);
    }
  };

  return {
    ...state,
    start: async () => {
      const { promise, popup } = start(connectHref);

      popupRef.current = popup;

      safeSetState({ loading: true, error: null, response: null });

      try {
        const response = await promise;

        if (onResponse && typeof onResponse === "function") {
          onResponse(response);
        }

        safeSetState({ loading: false, error: null, response });
      } catch (error) {
        if (error instanceof Error) {
          safeSetState({ loading: false, error, response: null });
        }
      }

      if (popupRef.current) {
        popupRef.current.close();
        popupRef.current = null;
      }
    },
    cancel: () => {
      if (popupRef.current) {
        popupRef.current.close();
        popupRef.current = null;
      }

      safeSetState({ loading: false, error: null, response: null });
    },
  };
}

function start(url: string): {
  popup: Window | null;
  promise: Promise<IOAuthResponse>;
} {
  const popup = openPopup(url, "_blank");

  if (!popup) {
    return {
      popup,
      promise: Promise.reject(new Error("Failed to open popup")),
    };
  }

  return {
    popup,
    promise: new Promise((resolve, reject) => {
      listenForSuccess(popup, resolve, reject);
    }),
  };
}

let lastScheduledTimeout: NodeJS.Timeout;

function listenForSuccess(popup: Window, resolve: (response: IOAuthResponse) => void, reject: (error: Error) => void) {
  if (lastScheduledTimeout) {
    clearTimeout(lastScheduledTimeout);
  }

  let search: {
    response?: string;
  } | null = null;

  try {
    search = queryString.parse(popup.location.search);
  } catch (e) {
    search = null;
  }

  if (isObject(search) && search.response) {
    popup.close();
    clearTimeout(lastScheduledTimeout);

    const jsonResponse = JSON.parse(base64url.decode(search.response));
    resolve(jsonResponse);
    return;
  }

  if (popup.closed) {
    reject(new Error("User cancelled"));
    return;
  }

  lastScheduledTimeout = setTimeout(() => listenForSuccess(popup, resolve, reject), 100);
}

function openPopup(url: string, name: string): Window | null {
  const width = 600;
  const height = 600;
  const { top, left } = calculatePopupOffset(width, height);

  const params = [
    `width=${width}`,
    `height=${height}`,
    `top=${top}`,
    `left=${left}`,
    "scrollbars=no,toolbar=no,location=no,titlebar=no,directories=no,status=no,menubar=no",
  ];

  return window.open(url, name, params.join(","));
}

function calculatePopupOffset(width: number, height: number): { left: number; top: number } {
  const left = window.screenLeft ? window.screenLeft : window.screenX;
  const top = window.screenTop ? window.screenTop : window.screenY;

  return {
    left: window.innerWidth / 2 - width / 2 + left,
    top: window.innerHeight / 2 - height / 2 + top,
  };
}
